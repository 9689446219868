import React from 'react';
import PropTypes from 'prop-types';
import _capitalize from 'lodash/capitalize';
import Avatar from '../Avatar';
import { formatDate } from '../../utils/postUtils';

import styles from './postDetails.module.scss';

const PostDetails = ({ author, date }) => (
  <div className={styles.subHeader}>
    <Avatar author={author} size="small" />
    <div className={styles.postInfo}>
      <div className={styles.infoText}>{_capitalize(author)}</div>
      <div className={styles.infoText}>{formatDate(date)}</div>
    </div>
  </div>
);

PostDetails.propTypes = {
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default PostDetails;
