import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import _map from 'lodash/map';

import styles from './postCategories.module.scss';

const PostCategories = ({ categories }) => {
  return (
    <div className={styles.postCategoriesWrapper}>
      {_map(categories, ({ id, name, slug }) => (
        <Link key={id} to={`/blog/category/${slug}`} className={styles.category}>
          <div>{name}</div>
        </Link>
      ))}
    </div>
  );
};

PostCategories.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default PostCategories;
