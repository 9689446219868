import React from 'react';
import Recent from './components/Recent';
import PostedBy from './components/PostedBy';
import Categories from './components/Categories';

import styles from './sidebar.module.scss';

const Sidebar = () => {
  return (
    <aside className={styles.sidebarWrapper}>
      <Recent />
      <PostedBy />
      <Categories />
    </aside>
  );
};

export default Sidebar;
