import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import _toLower from 'lodash/toLower';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Sidebar from '../components/Sidebar';
import PostDetails from '../components/PostDetails';
import PostCategories from '../components/PostCategories';
import { cleanTitle, cleanContent, cleanExcerpt, getFeaturedImage } from '../utils/postUtils';

import styles from './blogPost.module.scss';

const BlogPost = props => {
  const { data, pageContext } = props;
  const { previous, next, currentPost, postCount } = pageContext;

  const post = data.wordpressPost;
  const { title, content, date, slug, categories } = post;

  const scrubbedTitle = cleanTitle(title);
  const scrubbedExcerpt = cleanExcerpt(content);
  const featuredImage = getFeaturedImage(content);
  const author = _toLower(post.author.name);
  const postContent = cleanContent(content);

  return (
    <Layout>
      <SEO
        title={`${scrubbedTitle} | Autism Parenting Journey`}
        description={scrubbedExcerpt}
        image={featuredImage}
        pathname={`/blog/${slug}`}
        article
      />
      <div className={styles.blogWrapper}>
        <article className={styles.singlePostWrapper}>
          <aside className={styles.postHeader}>
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: scrubbedTitle }} />
            <PostDetails date={date} author={author} />
            <PostCategories categories={categories} />
          </aside>
          <div className={styles.postContent}>
            <div className={styles.copy} dangerouslySetInnerHTML={{ __html: postContent }} />
          </div>

          <div className={styles.postEnd}>
            <span>
              [&nbsp;
              <Link to="/about">Here&apos;s why we share</Link>
              &nbsp;]
            </span>
          </div>

          <div className={styles.postEnd}>
            Follow us on Instagram{' '}
            <a
              href="https://www.instagram.com/autismparentingjourney/"
              target="_blank"
              rel="noopener noreferrer"
            >
              @autismparentingjourney
            </a>{' '}
            to get the latest updates.
          </div>

          <div className={styles.postNavWrapper}>
            {next && (
              <Link to={`/blog/${next}`}>
                <div className={styles.postNavLink}>&larr;</div>
              </Link>
            )}
            {previous && (
              <Link to={`/blog/${previous}`}>
                <div className={styles.postNavLink}>&rarr;</div>
              </Link>
            )}
          </div>
          <div className={styles.pageIdent}>
            Post {currentPost} of {postCount}
          </div>
        </article>
        <Sidebar />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    wordpressPost(slug: { eq: $slug }) {
      id
      slug
      title
      content
      date
      modified
      author {
        name
      }
      categories {
        name
        id
        slug
      }
    }
  }
`;

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default BlogPost;
