import React from 'react';
import { Link } from 'gatsby';
import _map from 'lodash/map';
import _toLower from 'lodash/toLower';
import Section from '../Section';

import styles from '../../sidebar.module.scss';

const PostedBy = () => {
  const authors = ['Blake', 'Emily'];
  const postedByLinks = _map(authors, author => {
    return (
      <div key={author} className={styles.sectionLink}>
        <Link to={`/blog/author/${_toLower(author)}`}>{author}</Link>
      </div>
    );
  });
  return <Section sectionName="Posted By">{postedByLinks}</Section>;
};

export default PostedBy;
